import Vue from 'vue'
import VueRouter from 'vue-router'
import { VerifyLog } from '../store/verifyLogget'

Vue.use(VueRouter)

const routes = [
    //  Ruta de prueba */
    {   
        path: '/',
        name: 'login',
        // component: () => import('../views/Login.vue'),  //CHLO
        component: () => process.env.VUE_APP_CLINICA==='cqc' ? import('../views/Login2.vue') : process.env.VUE_APP_CLINICA==='chlo' ? import('../views/Login.vue') : import('../views/Login3.vue') , // CQC

        meta: {
            requireAuth: false,
        }
    },

    {   
        path: '/verify/:token',
        name: 'verificar',
        // component: () => import('../views/Password/Verificar.vue'), //CHLO
        component: () => process.env.VUE_APP_CLINICA==='cqc' ? import('../views/Password/Verificar2.vue') : process.env.VUE_APP_CLINICA==='chlo' ? import('../views/Password/Verificar.vue') : import('../views/Password/Verificar3.vue'),  //CQC
        meta: {
            requireAuth: false,
        }
    },

    {   
        path: '/newPassword/:token',
        name: 'restablecer',
        // component: () => import('../views/Password/Restablecer.vue'),   //CHLO
        component: () => process.env.VUE_APP_CLINICA==='cqc' ? import('../views/Password/Restablecer2.vue') : process.env.VUE_APP_CLINICA==='chlo' ? import('../views/Password/Restablecer.vue') : import('../views/Password/Restablecer3.vue'),  //CQC
        meta: {
            requireAuth: false,
        }
    },
    // //  Termina ruta de prueba 

    {
        path: '/main',
        name: 'main',
        component: () => import('../views/Main.vue'),
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '/notas-medicas/:id',
        name: 'notas-medicas',
        component: () => import('../views/Paciente/NotasMedicas/Registro.vue'),
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '/especialidades',
        name: 'especialidades',
        component: () => import('../views/Paciente/Especialidades.vue'),
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '/pacientes/:id',
        name: 'pacientes',
        component: () => import('../views/Paciente/Pacientes.vue'),
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '/registro',
        name: 'registro',
        component: () => import('../views/Paciente/Registro/Main.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/registro/nuevo_expediente/:id',
        name: 'nuevo_exp',
        component: () => import('../views/Paciente/Registro/Main.vue'),
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '/perfil',
        name: 'perfil',
        component: () => import('../views/Usuario/Perfil.vue'),
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '/mis_pacientes',
        name: 'mis_pacientes',
        component: () => import('../views/Usuario/Medico/MisPacientes.vue'),
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '/administrador',
        name: 'administrador',
        component: () => import('../views/Usuario/Admin/Administrador.vue'),
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '/reg_especialidades',
        name: 'reg_especialidades',
        component: () => import('../views/Usuario/Admin/RegistroEspecialidades.vue'),
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '/catalogos',
        name: 'catalogos',
        component: () => import('../views/Catalogo/Main.vue'),
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '/registro_catalogo/:id',
        name: 'registroCat',
        component: () => import('../views/Catalogo/Registro.vue'),
        meta: {
            requireAuth: true,
        },
    },

    /* Enfermeria */
    {
        path: '/control/enfermeria/pacientes',
        name: 'pacientesEnfermeria',
        component: () => import('../views/Enfermeria/Listado.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/enfermeria',
        name: 'enfermeria',
        component: () => import('../views/Enfermeria/Main.vue'),
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: 'registro/hemodinamia/paciente/:id/expediente/:idExp',
                name: 'registroHemodinamia',
                component: () => import('../views/Enfermeria/Registro/Hemodinamia.vue'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: 'registro/clinico/paciente/:id/expediente/:idExp',
                name: 'registroClinico',
                component: () => import('../views/Enfermeria/Registro/Clinico.vue'),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: 'registro/quirofano/',
                name: 'registroQuirofano',
                component: () => import('../views/Enfermeria/Registro/Quirofano.vue'),
                meta: {
                    requireAuth: true,
                },
                children: [
                    {
                        path: 'preoperatorio/paciente/:id/expediente/:idExp',
                        name: 'preoperatorio',
                        component: () => import('../components/Enfermeria/Quirofano/Preoperatorio.vue'),
                        meta: {
                            requireAuth: true,
                        },
                    },
                    {
                        path: 'transoperatorio/paciente/:id/expediente/:idExp',
                        name: 'transoperatorio',
                        component: () => import('../components/Enfermeria/Quirofano/Transoperatorio.vue'),
                        meta: {
                            requireAuth: true,
                        },
                    },
                    {
                        path: 'postoperatorio/paciente/:id/expediente/:idExp',
                        name: 'postoperatorio',
                        component: () => import('../components/Enfermeria/Quirofano/Postoperatorio.vue'),
                        meta: {
                            requireAuth: true,
                        },
                    },
                ]
            },
            {
                path: 'registro/cirugia',
                name: 'registroCirugia',
                component: () => import('../views/Enfermeria/Registro/Cirugia.vue'),
                meta: {
                    requireAuth: true,
                },
                children: [
                    {
                        path: 'entrada/paciente/:id/expediente/:idExp',
                        name: 'entradaCirugia',
                        component: () => import('../components/Enfermeria/Cirugia/Entrada.vue'),
                        meta: {
                            requireAuth: true,
                        },
                    },
                    {
                        path: 'pausa/paciente/:id/expediente/:idExp',
                        name: 'pausaCirugia',
                        component: () => import('../components/Enfermeria/Cirugia/Pausa.vue'),
                        meta: {
                            requireAuth: true,
                        },
                    },
                    {
                        path: 'salida/paciente/:id/expediente/:idExp',
                        name: 'salidaCirugia',
                        component: () => import('../components/Enfermeria/Cirugia/Salida.vue'),
                        meta: {
                            requireAuth: true,
                        },
                    },
                ]
            }
        ]
    },

    
    {
        path: '*',
        redirect: '/main',
        meta: {
            requireAuth: true,
        },
    },

    {
        path: '*',
        redirect: '/',
    },

]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    const protectedRoute = to.matched.some(record => record.meta.requireAuth)
    if (to.name=='registro' || to.name == 'nuevo_exp') {
        Vue.prototype.$reIngreso = false
        Vue.prototype.$deAdmision = false
        Vue.prototype.$idPacienteAdmision = ""
    }
    if (to.name=='notas-medicas') {
        Vue.prototype.$sinExp = false
    }
    new Promise((resolve)=>{
        resolve(VerifyLog())
    }).then((data)=>{
        if (protectedRoute && !data) {
            localStorage.setItem('token',"")
            next({ name: 'login' })
        }else {
            next();
        }
    })
});

export default router;


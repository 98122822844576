import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
// import { EventBus } from '../EventBus'
import CryptoJS from 'crypto-js'
import { url } from "../../settings";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        auth: null,
        usuarioLog: {
            id: '',
            nombre: '',
            email:"",
            perfil: '',
            especialidad: '',
            cedula: ''
        }
    },

    cookie: {
        email: null,
        password: null,
        check: null
    },

    mutations: {
        setToken(state, payload) { 
            state.token = payload;
            state.auth=true;
        },
        
        doLogout(state) {
            state.auth = false;
            state.token = null;
        },

        setCookie(cookie, user) {
            var text = CryptoJS.AES.encrypt(user.password, 'secret key heal4701tech');//Use CryptoJS method to encrypt
            var saveDays = new Date();
            saveDays.setTime(saveDays.getTime() + 24 * 60 * 60 * 1000 * 7); //Number of days saved
            //String splicing and storing in cookie
            window.document.cookie = "promo_shown=1; Max-Age=2600000; Secure"
            window.document.cookie = "email" + "=" + user.user + ";path=/;sameSite=none;secure;expires=" + saveDays.toGMTString();
            window.document.cookie = "password" + "=" + text + ";path=/;sameSite=none;secure;expires=" + saveDays.toGMTString();
            window.document.cookie = "check" + "=" + user.check + ";path=/;sameSite=none;secure;expires=" + saveDays.toGMTString();
        },
    
        clearCookie() {
            window.document.cookie = "email=;secure"
            window.document.cookie = "password=;secure"
            window.document.cookie = "check=false;secure"
        }
    },

    actions: {

        async login({ commit },user) {
            var Sha256 = CryptoJS.SHA256;
            var Hex = CryptoJS.enc.Hex;
            var Utf8 = CryptoJS.enc.Utf8;
            var Base64 = CryptoJS.enc.Base64;
            var AES = CryptoJS.AES;

            var secret_key="7aE3OKIZxusugQdpk3gwNi9x63MRAFLgkMJ4nyil88ZYMyjqTSE3FIo8L5KJghfi"
            var secret_iv="7aE3OKIZxusugQdpk3gwNi9x63MRAFLgkMJ4nyil88ZYMyjqTSE3FIo8L5KJghfi"

            var key = Sha256(secret_key).toString(Hex).substr(0,32);
            var iv = Sha256(secret_iv).toString(Hex).substr(0,16);

            // Encryption
            var output = AES.encrypt(user.password, Utf8.parse(key), {
                        iv: Utf8.parse(iv),
               }).toString();
           
            var pswEncrypt = Utf8.parse(output).toString(Base64);
               
            console.log(pswEncrypt);
            
            const loginData = {
                email: user.user,
                password: user.password
            };


            try {
                const res = await fetch(`${url}/api/auth/login`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "x-access-token": "token-value",
                    },
                    body: JSON.stringify(loginData),
                });
                const userDB = await res.json();
                commit('setToken', userDB.access_token);
                localStorage.setItem('token', userDB.access_token);
                localStorage.setItem('expires_at', userDB.expires_at)

                // guardar galletas
                if (user.check == true) {
                    commit('setCookie',user)
                } else {
                    commit('clearCookie')
                }

                //obtener datos
                try {
                    const res = await fetch(`${url}/api/usuarios/logged`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                            'Authorization': "Bearer "+ localStorage.getItem("token"),
                        }
                    });
                    if (!res.ok) {
                        const message = `An error has occured: ${res.status} - ${res.statusText}`;
                        return {message,error:'accesoDenegado'}
                        // throw new Error(message);
                    }

                    const data = await res.json();
                    this.state.usuarioLog.id = data.usuario.id;
                    this.state.usuarioLog.nombre = data.usuario.nombre;
                    this.state.usuarioLog.perfil = data.usuario.perfil;
                    this.state.usuarioLog.email = data.usuario.email;
                    try {
                        this.state.usuarioLog.especialidad = data.usuario.especialidad.nombre;
                    } catch (error) {
                        console.log(error);
                    }
                    this.state.usuarioLog.cedula = data.usuario.cedula;
                    //local storage 
                    localStorage.setItem('id', data.usuario.id);
                    localStorage.setItem('nombre', data.usuario.nombre);
                    localStorage.setItem('perfil', data.usuario.perfil.id);
                    localStorage.setItem('perfilNombre', data.usuario.perfil.nombre);
                    localStorage.setItem('email', data.usuario.email);
                    localStorage.setItem('especialidad', data.usuario.especialidad);
                    localStorage.setItem('cedula', data.usuario.cedula);

                    // local storage encriptado perfil
                    var perfil = CryptoJS.AES.encrypt(JSON.stringify(data.usuario.perfil.id), 'secret key heal4701tech')
                    localStorage.setItem('perfil', perfil);

                    // local storage encriptado id
                    var id = CryptoJS.AES.encrypt(JSON.stringify(data.usuario.id), 'secret key heal4701tech')
                    localStorage.setItem('idEncrypt', id);

                    // local storage encriptado nombre
                    var nombre = CryptoJS.AES.encrypt(data.usuario.nombre, 'secret key heal4701tech')
                    localStorage.setItem('nombreEncrypt', nombre);

                    // local storage encriptado perfilNombre
                    var perfilNombre = CryptoJS.AES.encrypt(data.usuario.perfil.nombre, 'secret key heal4701tech')
                    localStorage.setItem('perfilNombreEncrypt', perfilNombre);

                    // local storage encriptado email
                    var email = CryptoJS.AES.encrypt(data.usuario.email, 'secret key heal4701tech')
                    localStorage.setItem('emailEncrypt', email);

                    // local storage encriptado cedula
                    var cedula = CryptoJS.AES.encrypt(data.usuario.cedula, 'secret key heal4701tech')
                    localStorage.setItem('cedulaEncrypt', cedula);

                     // local storage encriptado especialidad
                     try {
                         var especialidad = CryptoJS.AES.encrypt(data.usuario.especialidad.nombre, 'secret key heal4701tech')
                         localStorage.setItem('especialidadEncrypt', especialidad);
                     } catch (error) {
                        console.log("");
                     }

                    if (this.state.usuarioLog.perfil.id==1) {
                        router.push("/administrador")
                    }else if(this.state.usuarioLog.perfil.id==2){
                        router.push("/registro")
                    }else{
                        router.push("/main")
                    }
                } catch (error) {
                    console.log(error);
                }

            } catch (error) {
                return {message:error,error:'accesoDenegado'}
            }
       },

       getToken({ commit }) {
            if(localStorage.getItem('token')) {
                commit('setToken', localStorage.getItem('token'))
            } else {
                commit('setToken', null)
            }
       },
    }
})
import '@babel/polyfill'
import 'mutationobserver-shim'
import './plugins/bootstrap-vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import bootstrapVue from './plugins/bootstrap-vue'
import { css } from './helpers/switchCss'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import cryptoJs from 'crypto-js'

Vue.config.productionTip = false
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)
Vue.prototype.$currentExp = ""
Vue.prototype.$error500 = false
Vue.prototype.$reIngreso = false
Vue.prototype.$deAdmision = false
Vue.prototype.$sinExp = false
Vue.prototype.$idPacienteAdmision = ""

new Vue({
  render: h => h(App),
  router,
  store,
  css,
  bootstrapVue,
  VueTypeaheadBootstrap,
  cryptoJs
}).$mount('#app')

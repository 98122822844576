
export const url = process.env.VUE_APP_ENDPOINT
export const clinica = process.env.VUE_APP_CLINICA;

export const showNotas = {
    tipo_usuario: [
        // admin
        {
            id: 1,
            show: {
                frontal_expediente: true,
                hoja_admision: true,
                historia_clinica: true,
                nota_consulta: true,
                consentimientos: {
                    carta: true,
                    anestesiologia: true,
                    hemodinamia: true
                },
                notas: {
                    hoja_ingreso: true,
                    nota_ingreso: true,
                    nota_evolucion: true,
                    pre_operatoria: true,
                    pre_anestesica: true,
                    post_operatoria: true,
                    nota_indicaciones: true,
                    nota_egreso: true,
                }
            }
        },


        // resepcionista
        {
            id: 2,
            show: {
                frontal_expediente: true,
                hoja_admision: true,
                historia_clinica: true,
                nota_consulta: false,
                consentimientos: {
                    carta: true,
                    anestesiologia: true,
                    hemodinamia: true
                },
                notas: {
                    hoja_ingreso: true,
                    nota_ingreso: false,
                    nota_evolucion: false,
                    pre_operatoria: false,
                    pre_anestesica: false,
                    post_operatoria: false,
                    nota_indicaciones: false,
                    nota_egreso: false,
                }
            }
        },

        // medico
        {
            id: 3,
            show: {
                frontal_expediente: true,
                hoja_admision: true,
                historia_clinica: true,
                nota_consulta: true,
                consentimientos: {
                    carta: true,
                    anestesiologia: true,
                    hemodinamia: true
                },
                notas: {
                    hoja_ingreso: true,
                    nota_ingreso: true,
                    nota_evolucion: true,
                    pre_operatoria: true,
                    pre_anestesica: true,
                    post_operatoria: true,
                    nota_indicaciones: true,
                    nota_egreso: true,
                }
            }
        },

        // enfermeria
        {
            id: 4,
            show: {
                frontal_expediente: true,
                hoja_admision: true,
                historia_clinica: true,
                nota_consulta: true,
                consentimientos: {
                    carta: true,
                    anestesiologia: true,
                    hemodinamia: true
                },
                notas: {
                    hoja_ingreso: true,
                    nota_ingreso: true,
                    nota_evolucion: true,
                    pre_operatoria: true,
                    pre_anestesica: true,
                    post_operatoria: true,
                    nota_indicaciones: true,
                    nota_egreso: true,
                }
            }
        }
    ]
}
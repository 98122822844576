import { url } from '../../settings'

export const VerifyLog = async () => {
    try {
        const res = await fetch(`${url}/api/usuarios/logged`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': "Bearer "+ localStorage.getItem("token"),
            }
        });
        const data = await res.json();
        if (data.status) {
            return true
        }else{
            return false
        }
    } catch (error) {
        console.log("");
    }
}